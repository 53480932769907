import React from 'react';
import { Document, Page } from 'react-pdf';

import "./pdf-preview.scss";

export default class PdfPreview extends React.Component {
    state = {
        numOfPages: null,
        pageWidth: null,
        devicePixelRatio: null,
    };
    resizeTimeout;

    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
    }

    componentDidMount() {
        this.setState({ devicePixelRatio: this.getDevicePixelRatio() });
        this.setPageWidth();
        window.addEventListener('resize', this.windowResizeListener);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.windowResizeListener);
    }

    render() {
        return (
            <div ref={this.containerRef}
                style={{
                    maxWidth: `100vw`,
                    height: `100vh`,
                    overflowX: `hidden`,
                }}>
                <Document file={this.props.pdfData}
                    loading={''}
                    onLoadSuccess={this.onDocumentLoadSuccess}>
                    {this.getPages()}
                </Document>
            </div>
        );
    }

    getDevicePixelRatio = () => {
        return window.devicePixelRatio
            || window.screen.availWidth / document.documentElement.clientWidth;
    }

    windowResizeListener = () => {
        window.clearTimeout(this.resizeTimeout);

        if (this.isZoomEvent()) {
            this.setState({ devicePixelRatio: this.getDevicePixelRatio() });
            return;
        }

        const debounceTime = 90;
        this.resizeTimeout = setTimeout(() => {
            this.setPageWidth();
        }, debounceTime);
    };

    isZoomEvent = () => {
        return this.getDevicePixelRatio() !== this.state.devicePixelRatio;
    }

    setPageWidth = () => {
        const { offsetWidth: innerWidth } = this.containerRef.current;

        const pdfMaxWidth = 1000;

        const pageWidth = innerWidth > pdfMaxWidth ? pdfMaxWidth : innerWidth;
        this.setState({ pageWidth });
    }

    eventTriggeredByZoom = (currentWidth) => {
        return this.state.pageWidth === currentWidth;
    }

    onDocumentLoadSuccess = ({ numPages: numOfPages }) => {
        this.setState({ numOfPages });
    };

    getPages = () => {
        if (this.state.numOfPages == null) {
            return;
        }

        const pages = [];
        for (let i = 0; i < this.state.numOfPages; i++) {
            pages.push(
                <Page
                    className={'pdf-preview-page'}
                    key={i}
                    pageNumber={i + 1}
                    width={this.state.pageWidth} />
            );
        }
        return pages;
    };

}