import React from 'react';

import PdfPreview from "../components/pdf-preview/pdf-preview"
import * as pdfData from "../../static/programs/program.pdf"

class ProgramyPage extends React.Component {

    render() {
        return (
          <PdfPreview pdfData={pdfData}></PdfPreview>
        )
    }
}

export default ProgramyPage